@import url(https://fonts.googleapis.com/css2?family=Rambla:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Questrial&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Public+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&family=Staatliches&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
*{
    margin:0;
    padding: 0;
    outline: none;
    list-style: none;
    
}
